import { InputBase, withStyles } from "@material-ui/core";

const BasicInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "&.MuiInputBase-multiline": {
        padding: "0 !important",
      },
    },
    input: {
      position: "relative",
      color: "rgb(8 40 82)",
      fontSize: "14px !important",
      margin: "0 !important",
      height: "2.5rem !important",
      padding: "10px 12px 10px  !important",
      borderRadius: "4px !important",
      border: "1px solid #ced4da !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
  
      "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
      },
    },
  }))(InputBase);

  export default BasicInput;